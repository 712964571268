import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { typeMapper, typeNameMapper } from '../../utils/asset-type-mapper';
import axios from '../axios';
import { Summary } from '../types';

const SummaryComponent = (props: { type: string; network: string }) => {
  const [summary, setSummary] = useState<Summary>();
  const [price, setPrice] = useState();

  useEffect(() => {
    try {
      axios.get('/prices/gold').then(({ data }) => {
        setPrice(data.physicalGold);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get('/summary', { params: { type: props.type, network: props.network } })
        .then(r => {
          setSummary(r.data);
        })
        .catch((e: any) => {
          console.error(e);
        });
    } catch (e: any) {
      console.error(e);
    }
  }, [props.network, props.type]);

  if (!summary) {
    return null;
  }

  return (
    <Box sx={{ color: '#F7F6F2', lineHeight: 1.71 }}>
      <div>
        <Typography
          sx={{
            display: 'inline-block',
            fontFamily: 'Sora, sans-serif !important',
            fontSize: '14px',
            fontWeight: '600',
          }}
          component="h3"
          variant="h6"
        >
          Total LBMA {props.type.toUpperCase()} in circulation (Fine Oz):
        </Typography>
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {Number(summary?.lbma || 0).toFixed(2)}
        </span>
      </div>
      <div>
        <Typography
          sx={{
            display: 'inline-block',
            fontFamily: 'Sora, sans-serif !important',
            fontSize: '14px',
            fontWeight: '600',
          }}
          component="h3"
          variant="h6"
        >
          Total {typeMapper[props.type]} in circulation:
        </Typography>
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {Number(summary?.dgld || 0).toFixed(2)}
        </span>
      </div>
      <div>
        <Typography
          sx={{
            display: 'inline-block',
            fontFamily: 'Sora, sans-serif !important',
            fontSize: '14px',
            fontWeight: '600',
          }}
          component="h3"
          variant="h6"
        >
          {typeNameMapper[props.type]} price (USD):
        </Typography>
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          {Number(price || 0).toFixed(2)}
        </span>
      </div>
    </Box>
  );
};

export default SummaryComponent;
