import { Typography } from '@mui/material';

import WalletTable from '../table/wallet';

const WalletDetails = ({ data, reset, onSelect }: any) => {
  return (
    <div style={{ padding: '0rem' }}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          sx={{
            fontSize: '26px',
            textAlign: 'center',
            color: '#0A1F19',
            marginBottom: '64px',
            '@media (max-width: 736px)': { marginBottom: '53px', fontSize: '22px' },
          }}
          component="h4"
        >
          Your balance is comprised of the following bars
        </Typography>
      </div>
      <WalletTable onSelect={onSelect} data={data} />
    </div>
  );
};

export default WalletDetails;
