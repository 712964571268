import { Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

const AssetDetails = ({ asset, data, reset, type, explorerUrl }: any) => {
  const selectedAsset = data.rows.filter((row: any) => row.id === asset[0])[0];

  return (
    <div className="popup">
      <Paper
        sx={{
          padding: '30px 64px 120px',
          maxWidth: '1200px',
          background: '#fff',
          borderRadius: '16px',
          boxSizing: 'border-box',
          position: 'relative',
          width: '100%',
          color: '#0A1F19',
          lineHeight: 1.875,
          '@media (max-width: 736px)': {
            padding: '24px 16px',
            maxHeight: '100%',
            overflow: 'auto',
          },
        }}
      >
        <img className="popup__logo" alt="DGLD Logo" src="/logo.svg" />
        <button className="popup__button" onClick={() => reset()}></button>
        <Typography
          component="h3"
          variant="h3"
          sx={{ marginBottom: '26px', fontSize: '28px', fontWeight: 'normal' }}
        >
          LBMA {type.toUpperCase()} Bar Integrity certificate
        </Typography>
        <Grid
          sx={{
            columns: 2,
            gap: '40px',
            '@media (max-width: 736px)': {
              columns: 1,
            },
          }}
        >
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Asset ID:
            </Typography>
            <span>{selectedAsset.id}</span>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Metal:
            </Typography>
            <span>{type.toUpperCase()}</span>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Fineness:
            </Typography>
            <span>{selectedAsset.fineness}</span>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Fine Weight (Oz):
            </Typography>
            <span>{selectedAsset.weight}</span>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Bar Number:
            </Typography>
            <span>{selectedAsset.id.split('-')[1]}</span>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Producer:
            </Typography>
            <span>{selectedAsset.id.split('-')[0]}</span>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Country:
            </Typography>
            <span>Switzerland</span>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Year produced:
            </Typography>
            <span>{selectedAsset.id.split('-')[2]}</span>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Tokenisation Information
            </Typography>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Tokenisation date:
            </Typography>
            <span>{new Date(`${selectedAsset.creationDate}`).toLocaleDateString()}</span>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Creation TX:
            </Typography>
            <a
              style={{ wordBreak: 'break-all' }}
              target="_blank"
              rel="noreferrer"
              href={`${explorerUrl}/tx/${selectedAsset.creationTransaction}`}
            >
              {selectedAsset.creationTransaction}
            </a>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Audit Report
            </Typography>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Deposit certification:
            </Typography>
            <a style={{ color: 'currentcolor' }} href={selectedAsset.certificate}>
              Click to View
            </a>
          </div>
          <div>
            <Typography
              sx={{ display: 'inline-block', fontWeight: '600', mr: '5px' }}
              component="p"
            >
              Inventory report:
            </Typography>
            <a style={{ color: 'currentcolor' }} href={selectedAsset.report}>
              Click to View
            </a>
          </div>
          <img
            className="popup__stamp"
            alt=""
            src="/stamp.png"
            width="310"
            height="180"
            loading="lazy"
          />
          <img className="popup__gold-bar" alt="" src="/gold-bar.png" loading="lazy" />
        </Grid>
      </Paper>
    </div>
  );
};

export default AssetDetails;
