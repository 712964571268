import React, { useState } from 'react';

import Modal from '../modal/modal';

const Footer = () => {
  const [isActive, setIsActive] = useState('');

  return (
    <div>
      <div className="cta">
        <div className="cta__content">
          <h2>Start investing in digital gold today</h2>
          <Modal />
        </div>

        <img
          className="cta__image"
          src="/cta-bg.jpg"
          width="400"
          height="220"
          loading="lazy"
          alt=""
        />
      </div>

      <footer className="footer">
        <div className="footer__logo">
          <a href="https://dgld.ch">
            <img alt="DGLD Logo" src="/logo.svg" />
            <p>Physical Gold Made Digitally Useful</p>
          </a>
        </div>

        <nav className="footer__nav">
          <div>
            <h2 onClick={() => (isActive === '' ? setIsActive('company') : setIsActive(''))}>
              Company
              <img className={isActive === 'company' ? 'is-active' : ''} src="/chev.svg" alt="" />
            </h2>
            {(isActive === 'company' || window.innerWidth > 736) && (
              <ul>
                <li>
                  <a href="https://dgld.ch/news">News & Insights</a>
                </li>
                <li>
                  <a href="https://dgld.ch/partner">Partner</a>
                </li>
              </ul>
            )}
          </div>

          <div>
            <h2 onClick={() => (isActive === '' ? setIsActive('docs') : setIsActive(''))}>
              Resources
              <img className={isActive === 'docs' ? 'is-active' : ''} src="/chev.svg" alt="" />
            </h2>
            {(isActive === 'docs' || window.innerWidth > 736) && (
              <ul>
                <li>
                  <a href="https://dgld.ch/assets/documents/dgld-whitepaper.pdf">Whitepaper</a>
                </li>
                <li>
                  <a href="https://explorer.dgld.ch/">Bar mapper</a>
                </li>
              </ul>
            )}
          </div>

          <div>
            <h2 onClick={() => (isActive === '' ? setIsActive('learn') : setIsActive(''))}>
              Learn
              <img className={isActive === 'learn' ? 'is-active' : ''} src="/chev.svg" alt="" />
            </h2>
            {(isActive === 'learn' || window.innerWidth > 736) && (
              <ul>
                <li>
                  <a href="https://dgld.ch/faqs">FAQs</a>
                </li>
                <li>
                  <a href="https://dgld.ch/support">Support</a>
                </li>
              </ul>
            )}
          </div>

          <div>
            <h2 onClick={() => (isActive === '' ? setIsActive('legal') : setIsActive(''))}>
              Legal
              <img className={isActive === 'legal' ? 'is-active' : ''} src="/chev.svg" alt="" />
            </h2>
            {(isActive === 'legal' || window.innerWidth > 736) && (
              <ul>
                <li>
                  <a href="https://dgld.ch/legal/disclaimer">Disclaimer</a>
                </li>
                <li>
                  <a href="https://dgld.ch/legal/privacy">Privacy</a>
                </li>
                <li>
                  <a href="https://dgld.ch/legal/terms-conditions">Terms & Conditions</a>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </footer>
    </div>
  );
};

export default Footer;
