const font = { fontFamily: 'Sora, sans-serif !important' };
const fontHeadings = { fontFamily: 'DM Serif Display, serif !important' };
// const bold = { fontWeight: 'bold !important' };
const semibold = { fontWeight: '600 !important' };

export default {
  body: { ...font },
  html: { ...font },
  h1: { ...fontHeadings, ...semibold },
  h2: { ...fontHeadings, ...semibold },
  h3: { ...fontHeadings, ...semibold },
  h4: { ...fontHeadings, ...semibold },
  h5: { ...fontHeadings, ...semibold },
  input: { ...font },
  label: { ...font },
  p: { ...font },
  button: { ...font },
  div: { ...font },
  span: { ...font },
};
