import { useState } from 'react';

import { typeMapper } from '../../utils/asset-type-mapper';
import Modal from '../modal/modal';

const HeaderBar = (props: { type: string }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <header className={isActive ? 'mobile-nav--active' : ''}>
      <button className="header-toggle" onClick={() => setIsActive(!isActive)}>
        <img src={isActive ? '/close-icon.svg' : '/menu-icon.svg'} alt="" />
      </button>

      <a href="https://dgld.ch/" className="header-logo">
        <img alt="DGLD Logo" src={isActive ? '/logo-white.svg' : '/logo.svg'} />
      </a>
      <ul className="header-list">
        <li>
          <a className="header-list__item" href="https://dgld.ch/faqs">
            What is {typeMapper[props.type]}?
          </a>
        </li>
        <li>
          <a className="header-list__item" href="https://dgld.ch/news">
            News &amp; Insights
          </a>
        </li>
        <li>
          <a className="header-list__item" href="https://dgld.ch/partner">
            Partner
          </a>
        </li>
        <li>
          <a className="header-list__item" href="https://dgld.ch/support">
            Support
          </a>
        </li>
        <li>
          <Modal />
        </li>
      </ul>
      <div className="mobile-nav">
        <ul>
          <li>
            <a href="https://dgld.ch/faqs">What is DGLD?</a>
          </li>
          <li>
            <a href="https://dgld.ch/news">News &amp; Insights</a>
          </li>
          <li>
            <a href="https://dgld.ch/partner">Partner</a>
          </li>
          <li>
            <a href="https://dgld.ch/support">Support</a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default HeaderBar;
