import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const AssetsTable = (props: { data: any; onSelect: any }) => {
  return (
    <DataGrid
      style={{
        marginBottom: '120px',
        height: 400,
        '@media (max-width: 736px)': { marginBottom: '80px' },
      }}
      onSelectionModelChange={newSelectionModel => {
        props.onSelect(newSelectionModel);
      }}
      {...props.data}
      components={{ Toolbar: GridToolbar }}
    />
  );
};

export default AssetsTable;
