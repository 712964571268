import { Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const AssetsTable = (props: { data: any; onSelect: any }) => {
  return (
    <div>
      <Typography
        sx={{
          fontSize: '26px',
          textAlign: 'center',
          color: '#0A1F19',
          marginBottom: '64px',
          '@media (max-width: 736px)': { marginBottom: '53px', fontSize: '22px' },
        }}
        component="h4"
      >
        Asset Mapper / Bar list - LBMA Approved Bars
      </Typography>
      <DataGrid
        sx={{
          marginBottom: '120px',
          height: 400,
          '@media (max-width: 736px)': { marginBottom: '80px' },
        }}
        onSelectionModelChange={newSelectionModel => {
          props.onSelect(newSelectionModel);
        }}
        {...props.data}
        components={{ Toolbar: GridToolbar }}
      />
    </div>
  );
};

export default AssetsTable;
