import { Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import { typeMapper } from '../../utils/asset-type-mapper';

const WalletInfo = ({
  data,
  asset,
}: {
  data: any;
  asset: { type: string; network: string; explorerUrl: string };
}) => {
  return (
    <Grid
      sx={{
        paddingTop: '40px',
        marginTop: '64px',
        borderTop: '1px solid #194D3E',
        paddingLeft: '64px',
        paddingRight: '64px',
        marginLeft: '-64px',
        marginRight: '-64px',
        '@media (max-width: 736px)': {
          marginTop: '43px',
          paddingTop: '32px',
          paddingLeft: '24px',
          paddingRight: '24px',
          marginLeft: '-24px',
          marginRight: '-24px',
        },
      }}
    >
      <Paper
        sx={{
          background: '#0A1F19',
          fontSize: '16px',
          lineHeight: 1.875,
        }}
      >
        <div>
          <Typography
            color={'white'}
            sx={{ display: 'inline-block', fontWeight: 600 }}
            component="p"
          >
            Ethereum Address:{' '}
          </Typography>{' '}
          <a
            target={'_blank'}
            href={`${asset.explorerUrl}/address/${data.address}`}
            style={{ display: 'block', wordBreak: 'break-all', color: '#F7E733' }}
            rel="noreferrer"
          >
            {data.address}
          </a>
        </div>
        <div>
          <Typography
            color={'white'}
            sx={{ display: 'inline-block', fontWeight: 600 }}
            component="p"
          >
            Total balance:{' '}
          </Typography>{' '}
          <span style={{ color: 'white' }}>
            {data.balance} {typeMapper[asset.type]}
          </span>
        </div>
      </Paper>
    </Grid>
  );
};

export default WalletInfo;
