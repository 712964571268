import { assetColumns, assetInitialState, walletColumns, walletInitialState } from './columns';

interface ApiResponse {
  data: any[];
  type: string;
  address?: string;
  balance?: number;
}

const Mapper = ({ data, type, address, balance }: ApiResponse) => {
  if (type === 'assetId') {
    return {
      type,
      columns: assetColumns,
      initialState: assetInitialState,
      responsive: 'scroll',
      rows: data.map((asset: any) => ({
        id: asset.id,
        fineness: Number(asset.fineness).toFixed(2),
        weight: Number(asset.fineWeight).toFixed(2),
        creationDate: asset.creationDate,
        creationTransaction: asset.creationTransaction,
        amount: asset.fineWeight,
        participants: asset.authorizedParticipant,
        custodian: asset.custodian,
        certificate: asset.certificateOfDeposit,
        report: asset.inventoryReport,
      })),
    };
  } else {
    return {
      type,
      address,
      balance: `${Math.round((balance ?? 0) * 100) / 100}`,
      gold: 'missing from api',
      columns: walletColumns,
      rows: data.map(
        ({
          id,
          fineness,
          fineWeight,
          ownedPortion,
          allocated,
          creationDate,
          creationTransaction,
          certificateOfDeposit,
          inventoryReport,
        }: any) => ({
          id,
          fineness: Number(fineness).toFixed(2),
          weight: Number(fineWeight).toFixed(2),
          owned: Number(ownedPortion).toFixed(2),
          allocated: Number(allocated).toFixed(2),
          creationDate: creationDate,
          creationTransaction: creationTransaction,
          certificate: certificateOfDeposit,
          report: inventoryReport,
        })
      ),
      initialState: walletInitialState,
    };
  }
};

export default Mapper;
