export const assetColumns = [
  {
    field: 'id',
    headerName: 'Asset ID',
    // width: 400,
    flex: 0.5,
    minWidth: 200,
    hideSortIcons: true,
    disableColumnMenu: true,
    resizable: true,
  },
  // {
  //   field: 'fineness',
  //   headerName: 'Fineness',
  //   flex: 0.3,
  //   minWidth: 150,
  //   filterable: false,
  //   hideSortIcons: true,
  //   disableColumnMenu: true,
  //   resizable: true,
  // },
  // {
  //   field: 'weight',
  //   headerName: 'Fine Weight (Oz)',
  //   // width: 400,
  //   flex: 0.7,
  //   minWidth: 200,
  //   filterable: false,
  //   disableColumnMenu: true,
  //   resizable: true,
  // },
  {
    field: 'creationDate',
    headerName: 'Creation date',
    flex: 0.6,
    minWidth: 200,
    filterable: false,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'creationTransaction',
    headerName: 'Creation TX',
    // width: 150,
    flex: 0.6,
    minWidth: 200,
    filterable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'amount',
    headerName: 'Created',
    // width: 150,
    flex: 0.7,
    minWidth: 200,
    filterable: false,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'participants',
    headerName: 'Authorised participants',
    // width: 150,
    flex: 0.4,
    minWidth: 200,
    filterable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    flex: 0.4,
    minWidth: 200,
    filterable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'certificate',
    headerName: 'Certificate of deposit',
    // width: 200,
    flex: 0.7,
    minWidth: 200,
    filterable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    resizable: true,
  },
  {
    field: 'report',
    headerName: 'Inventory report',
    // width: 150,
    flex: 0.7,
    minWidth: 200,
    filterable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    resizable: true,
  },
];

export const assetInitialState = {
  columns: {
    columnVisibilityModel: {
      id: true,
      fineness: true,
      weight: true,
      creationDate: true,
      creationTransaction: true,
      amount: true,
      participants: true,
      custodian: true,
      certificate: true,
      report: true,
    },
  },
};

export const walletColumns = [
  {
    field: 'id',
    headerName: 'Asset ID',
    flex: 0.5,
    minWidth: 150,
  },
  {
    field: 'fineness',
    headerName: 'Fineness',
    flex: 0.5,
    minWidth: 150,
  },
  {
    field: 'weight',
    headerName: 'Fine Weight (Oz)',
    flex: 0.5,
    minWidth: 200,
  },
  {
    field: 'owned',
    headerName: 'Owner Portion (Oz)',
    flex: 0.5,
    minWidth: 200,
  },
  {
    field: 'allocated',
    headerName: '% of Total Balance',
    flex: 0.5,
    minWidth: 150,
  },
];

export const walletInitialState = {
  columns: {
    columnVisibilityModel: {
      id: true,
      fineness: true,
      weight: true,
      owned: true,
      allocated: true,
    },
  },
};
