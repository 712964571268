import Grid from '@mui/material/Grid';
import React from 'react';

const ErrorMessage = ({ error }: { error: string }) => {
  return (
    <Grid
      sx={{
        color: '#F7F6F2',
        width: '100%',
        paddingBlock: '20px',
      }}
    >
      {error}
    </Grid>
  );
};

export default ErrorMessage;
