import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';

import mapper from '../../utils/mapper';
import axios from '../axios';
import ErrorMessage from '../error';

const SearchBox = ({
  callback,
  asset,
}: {
  callback: any;
  asset: { type: string; network: string };
}) => {
  const [error, setError] = useState('');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    id && search(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchValue) {
      search(searchValue);
    }
  }, [asset]);

  const search = async (id: string) => {
    try {
      const results: any = await axios
        .post('/search', { id, type: asset.type, network: asset.network })
        .catch((e: any) => {
          console.error(e);
          setError(`Sorry, there has been an error.`);
        });

      if (results.data.data.length) {
        setError(``);
        callback(mapper(results.data));
      } else {
        setError(`No results found.`);
        callback(
          mapper({
            ...results.data,
            address: id,
          })
        );
      }
    } catch (e: any) {
      console.error(e);
      setError(`Sorry, there has been an error.`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'flex-end',
        color: '#F7F6F2',
        flexDirection: 'column',
        '@media (max-width: 736px)': {
          gap: '24px',
          flexDirection: 'column',
        },
      }}
    >
      <Grid
        sx={{
          width: '100%',
          paddingLeft: 0,
          outline: 'none',
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'row',
          justifyContent: 'end',
          '@media (max-width: 736px)': {
            gap: '24px',
            flexDirection: 'column',
          },
        }}
      >
        <TextField
          sx={{
            width: '100%',
            color: '#F7F6F2',
            borderBottom: '2px solid #F7F6F2',
            outline: 'none',
          }}
          id="search-term"
          label="Search Wallet Address or Asset ID"
          placeholder="E.g. 2019 for bar list or 0x...eB for wallet address"
          value={searchValue}
          onChange={data => {
            setSearchValue(data.target.value);
          }}
          // inputProps={{
          //   style: { color: '#fff', outline: 'none', paddingLeft: '0' },
          // }}
          inputProps={{
            sx: {
              color: '#fff',
              outline: 'none',
              paddingLeft: '0',
              '&::placeholder': {
                color: '#fff',
              },
            },
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              color: '#fff',
              fontSize: '13px',
              opacity: 0.64,
              top: '-15px',
              transform: 'none',
            },
          }}
        />
        <IconButton
          sx={{
            color: '#F7F6F2',
            borderRadius: '100px',
            border: '2px solid #F7E733',
            backgroundColor: 'rgba(247, 231, 51, 0.08)',
            padding: '0.5rem 1rem',
            marginLeft: '1rem',
            height: '39px',
            fontSize: '15px',
            width: '100%',
            maxWidth: '102px',
            flexShrink: 0,
            '@media (max-width: 736px)': {
              maxWidth: '100%',
            },
          }}
          id="searchButton"
          onClick={() => search((document!.getElementById('search-term') as any).value!)}
        >
          Search
        </IconButton>
      </Grid>
      <ErrorMessage error={error} />
    </Box>
  );
};

export default SearchBox;
